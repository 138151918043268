import Hero from '../components/Hero';

function Home() {
  return (
    <>
      <Hero text="Hello World!" />
    </>
  );
}

export default Home;
