import Hero from '../components/Hero';

function About() {
  return (
    <>
      <Hero text="This is about page" />
    </>
  );
}

export default About;
